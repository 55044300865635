import React, { useContext, useRef, useState } from "react";
import { AppContext } from "contexts/AppContext";
import Translate, { translate } from "utils/Translate";
import Image from "components/elements/Image";
import Headline3 from "components/elements/Headline3";
import "./PersonalShopper.css";
import CustomInput2 from "components/elements/CustomInput2";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { callAPI } from "utils/API";
import { newLineToBreakTag } from "utils/general";

let mailInit = {
  nameOfProduct: "",
  link: "",
  specification: ""
}

const PersonalShopper = () => {
  const contextState = useContext(AppContext);
  const { styleGuide, brandData } = contextState;
  const bannerImageHeight = 560;
  const personalShopperBannerImage =
    brandData.features.personalShopperBannerImageURL ||
    "https://i.imgur.com/NPB3IhS.png";
  const imageHeight = brandData.features.personalShopperImageHeight
    ? `${brandData.features.personalShopperImageHeight}px`
    : bannerImageHeight;
  const writeToUsRef = useRef(null)
  
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!!personalShopperBannerImage ? (
          <>
            <Image
              src={`url(${personalShopperBannerImage})`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                height: imageHeight,
                width: "100%",
              }}
            ></Image>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: imageHeight,
          marginRight: 24,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: bannerImageHeight / 2,
          }}
        >
        </div>
      </div>
      <WriteToUs writeToUsRef={writeToUsRef} imageHeight={imageHeight}/>
      <div className="wantaProduct"></div>
    </>
  );
};

export const WriteToUs = (props) => {
  const contextState = useContext(AppContext);
  const { styleGuide, brandData, langCode} = contextState;
  const [mail, setMail] = useState(mailInit)
  const personalShopperTextColor = brandData.features.personalShopperTextColorBlack ? styleGuide.color18 : styleGuide.color1;
  const sendMailToUs =  async () => {
    const response = await callAPI("/personalShopper/writetous","POST", {body: {mailContent: mail, brand: brandData}})
    if(response.message === "Email sent Sucessfully") {
      alert("Din besked er afsendt")
    } else {
      alert(response.error)
    }
    setMail(mailInit)
  }
  let { personalShopperDescription } = brandData && brandData.features
  const description = personalShopperDescription && typeof personalShopperDescription === 'object' ?
    personalShopperDescription.hasOwnProperty(langCode) ? personalShopperDescription[langCode] : 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium, illum doloribus. Corrupti aut sint praesentium mollitia, nihil aperiam alias fugiat inventore.'
    : personalShopperDescription ? personalShopperDescription : 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium, illum doloribus. Corrupti aut sint praesentium mollitia, nihil aperiam alias fugiat inventore.'
  
  return <>
    <div ref={props.writeToUsRef} className="writeToUs" style={{ position: "absolute", top: props.imageHeight, left: 0, right: 0, zIndex: 0, width: "100%", backgroundColor: styleGuide.color14, height: "max-content" }}>
      <div className="personalShopperDetails">
        <Headline3
          style={{
            fontSize: 40,
            lineHeight: "54px",
            color: personalShopperTextColor,
            fontWeight: 600,
          }}
        >
          Personal Shopper
        </Headline3>
        <p
          className="personalShopperDescription"
          style={{
            marginTop: "20px",
            color: personalShopperTextColor,
          }}
        >
          {newLineToBreakTag(description)}
        </p>
      </div>
      <div className="writeToUsForm">
        <Headline3
          style={{
            fontSize: 40,
            lineHeight: "54px",
            color: personalShopperTextColor,
            fontWeight: 600,
          }}
        >
        {translate('_WRITE_TO_US', 'Skriv til os')}
        </Headline3>
        <p className="writeToUsNote" style={{fontSize: "18px", lineHeight: "28px",  color: personalShopperTextColor, marginTop: "30px"}}>
        {translate('_WRITE_TO_US_NOTE', 'Send os et link til det produkt, du godt kunne tænke dig. Så vil vores Personal Shopper se om det er muligt at opfylde dit ønske.')}
        </p>
        <CustomInput2 value={mail.nameOfProduct} placeholder={translate("_PERSONAL_SHOPPER_QUESTION_ENTITY_PLACEHOLDER_", "Skriv produkt, mærke eller lignende")} style={{color: personalShopperTextColor , marginBottom: "40px"}} placeholderColor={styleGuide.color16} onChange={(e)=>{setMail({...mail, nameOfProduct: e.target.value})}} />
        <CustomInput2 value={mail.link} placeholderColor={styleGuide.color16} placeholder={translate("_PERSONAL_SHOPPER_LINK_PLACEHOLDER_", "Indsæt link")} style={{color: personalShopperTextColor , marginBottom: "40px"}} onChange={(e)=>{setMail({...mail, link: e.target.value})}}/>
        <CustomInput2 value={mail.specification} placeholderColor={styleGuide.color16} placeholder={translate("_PERSONAL_SHOPPER_DETAILS_PLACEHOLDER_", "Indtast specifikationer: størrelse, farve, udgave eller lign.")} style={{color: personalShopperTextColor , marginBottom: "60px"}} onChange={(e)=>{setMail({...mail, specification: e.target.value})}}/>
        <ButtonLarge1 fallbackText="Send" style={{fontSize: "18px", lineHeight: "28px", borderRadius: "14px"}} onClick={sendMailToUs} ></ButtonLarge1>
      </div>
    </div>
  </>
}

export default PersonalShopper;
