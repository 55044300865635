import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { callAPI } from 'utils/API';
import { AppContext } from 'contexts/AppContext';
import CircularIndeterminate from 'components/blocks/progressBar/progressBar';
import { PublicClientApplication } from '@azure/msal-browser';
import { starkMsalConfig } from 'utils/msalAuthConfig';


const msalInstance = new PublicClientApplication(starkMsalConfig);

const SSO = () => {
    const location = useLocation();
    const { setUserData, brandData } = useContext(AppContext);
    const token = location.search ? new URLSearchParams(location.search).get('token') : null
    const idToken = location.hash.substring(1) || null
    const [user, setUser] = useState(null)
    const [redirectLogin, setRedirectLogin] = useState(false)    

    const validateSession = async (idToken) => {
        try {
            const sessionResponse = await callAPI(`/sessions/validate`, 'POST', {
                body: {
                    token: token, 
                    idToken,
                    deviceTimezone: Date().toString().match(/\((.*)\)/).pop(),
                    deviceLanguage: window.navigator.language,
                }
            });

            if (sessionResponse && sessionResponse.accessToken) {
                localStorage.clear();
                localStorage.setItem('accessToken', sessionResponse.accessToken);
                setUserData(sessionResponse.user);
                setUser(sessionResponse.user);
            } else {
                localStorage.clear();
                setUserData(null);
                setRedirectLogin(true);
            }
        } catch (error) {
        console.error('Session validation failed:', error);
        setRedirectLogin(true);
        }
    }

    useEffect(() => {
        const handleMSALAuth = async () => {
            try {
                const loginResponse = await msalInstance.handleRedirectPromise();
                if (loginResponse && loginResponse.idToken) {
                    validateSession(loginResponse);
                    msalInstance.setActiveAccount(loginResponse.account)
                } else {
                    msalInstance.loginRedirect({
                        scopes: ['openid', 'profile', 'email']
                    });
                }
            } catch (error) {
                console.error('MSAL login failed:', error);
                setRedirectLogin(true);
            }
        };
        if (brandData && brandData.BrandID === 87){
            handleMSALAuth();
        } else {
            setRedirectLogin(true);
        }
    }, []);

    return redirectLogin ? (
        <Navigate to={'/login'} replace={true} />
    ) : user && user.UserID ? (
        <Navigate to={'/'} replace={true} />
    ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem' }}>
            <CircularIndeterminate size={100} />
        </div>
    )
}

export default SSO;