import Headline3 from "components/elements/Headline3";
import Headline6 from "components/elements/Headline6";
import Image from "components/elements/Image";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Profile.css";
import { createUseStyles } from "react-jss";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";
import { Row, Col, Modal } from "react-bootstrap";
import Edit from "components/icons/Edit";
import RoundIconButton1 from "components/elements/RoundIconButton1";
import { callAPI } from "utils/API";
import { setupUserData } from "utils/setup";
import Headline2 from "components/elements/Headline2";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import Headline4 from "components/elements/Headline4";
import ContentLarge from "components/elements/ContentLarge";
import ContentSmall from "components/elements/ContentSmall";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import CustomInput from "components/elements/CustomInput";
import { translate } from "utils/Translate";
import CustomSelect from "components/elements/CustomSelect";
import ContentNormal from "components/elements/ContentNormal";
import CaptionText from "components/elements/CaptionText";
import { isValidEmail, isValidPhoneNumber, scrollToDiv} from "utils/general";
import GoToProductsBanner from "./GoToProductsBanner";
import SingleWishlistProduct from "./SingleWishlistProduct";
import Order from "components/blocks/order/Order";
import moment from "moment";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import Message from "components/blocks/message/Message";
import { StatisticSection } from "./Statistics";
import CustomCheckbox from "components/elements/CustomCheckbox";
import { useLocation } from "react-router-dom";
import { PointOverview } from "./PointsOverview";
import CountDownBlock from "components/blocks/countDownBlock/CountDownBlock";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import StatusSection from "./StatusSection";
import ActivationSales from "./ActivationSales";
import { formatNumber } from "utils/Translate";
import ActivationGridSection from "./ActivationGridSection";
import StatisticsSection2 from "./StatisticsSection2";
import ScrollButton from "components/elements/ScrollButton";
import CorrectAdvanceStatement from "./CorrectAdvanceStatement";
import { useMediaQuery } from "@material-ui/core";
import linkifyHtml from "linkify-html";
import KAMDashboard from "./KAMDashboard";

const jss = createUseStyles({
  ProfileImageSize: {
    position: 'relative',
    height: 200,
    width: 200,
    // top: 500,
    // left: 30,
  },
  ProfileImageEditButton: {
    // top: 470,
    // left: 148,
  },
  wishListMain: {
    marginTop: 200,
  },
  seeMoreButton: {
    position: "relative",
    marginTop: 30,
    alignSelf: "center"
  },
  OrderBlock:{
    marginTop: 100
  },
  "@media screen and (min-width: 768px)": {
    ProfileImageSize: {
      height: 254,
      width: 254,
      // top: 400,
      // left: 100,
    },
    ProfileImageEditButton: {
      // top: 370,
      // left: 350,
    },
    wishListMain: {
      marginTop: 200,
    },
  },
  "@media screen and (min-width: 1200px)": {
    OrderBlock: {
      marginTop: '160px !important',
    }
  },
  "@media screen and (min-width: 1280px)": {
    seeMoreButton: {
      position: "absolute",
      marginTop: 0,
      top: 15,
      right: 0,
    }
  },
  ProfileNote: { display: 'flex', position: "absolute", zIndex: 1, flexDirection: "column", gap: '1rem'}
});

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  "&.MuiBottomNavigation-root": {
    backgroundColor: "inherit",
    width: "auto",
    height: "80px",
  },
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    "&.MuiBottomNavigationAction-root": {
      color: "white",
      minWidth: 'max-content',
      "span.MuiBottomNavigationAction-label": {
        fontSize: "16px !important",
      },
    },
  })
);

const Profile = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Subpage } = useParams()
  const contextState = useContext(AppContext);
  const {
    styleGuide,
    brandData,
    userData,
    setUserData,
    accessibleShops,
    statusScroll,
    setStatusscroll,
    themeConfig
  } = contextState;
  const {enableOnePointUsers} = brandData?.features
  const bannerImageHeight = 560;
  const profileBannerImage =
    brandData.features.profileBannerImageURL ||
    "https://i.imgur.com/NPB3IhS.png";
  const profileBannerTitle = translate("_PROFILE_MENU_PROFILE_", "Profil");
  const imageWidth = brandData.features.profileBannerImageWidth
    ? `${brandData.features.profileBannerImageWidth}px`
    : bannerImageHeight;
  const imageHeight = parseInt(brandData.features.profileBannerImageHeight
    ? brandData.features.profileBannerImageHeight
    : bannerImageHeight);
  
  const [profileImage, setProfileImage] = useState(
    userData.attachments && userData.attachments.length
      ? userData.attachments[0].imageOptimizedSourceSmall
      : "https://static.upgrader.club/img/userthumb.png"
  );
  const [loading, setLoading] = useState(false);
  const classes = jss(styleGuide);
  const inputFile = useRef(null);
  const [isProductBannerEnable, setIsProductBannerEnable] = useState(true);
  const [activateComplete, setActivateComplete] = useState({pointBox: false, pointOverview: false, activationOverview: false, activationGraph: false});
  const [toggle, setToggle] = useState(false);
  const [isStarkBrand, setIsStarkBrand] = useState(false);
  const profileRef = useRef(null);
  const staticRef = useRef(null);
  const statusRef = useRef(null);
  const wishListRef = useRef(null);
  const competitionRef = useRef(null);
  const orderRef = useRef(null);
  const pointRef = useRef(null);
  const inboxRef = useRef(null);
  const activationSalesRef = useRef(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [scrollTargets, setScrollTargets] = useState([]);
  const enableProfileNote = brandData?.features?.enableProfileNote ? true : false
  const profileNote = enableProfileNote && brandData?.features?.profileNoteText ? brandData.features.profileNoteText : ''
  const isProfileNoteRow = useMediaQuery('(min-width: 1200px)') && enableProfileNote;
  const clearSession = () => {
    localStorage.clear();
    navigate("/logout");
  };
  
  if(location.hash.includes('#walletActivation')){
    activationSalesRef?.current && activationSalesRef?.current?.scrollIntoView({
      behavior: "smooth",
      alignToTop: true,
    });
  }

  const defaultShopID =
    accessibleShops && accessibleShops.length > 0
      ? accessibleShops[0].ShopID
      : null;

  const openFileInput = () => {
    // `current` points to the mounted file input element
    inputFile?.current?.click();
  };

  const updateProfileImage = (event) => {
    const file =
      event.target && event.target.files && event.target.files.length
        ? event.target.files[0]
        : null;
    if (!file) {
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      let _file = {
        File: file,
        base64String: reader.result,
        // biteArray: biteArray,
        contentType: file.type,
        fileExtension: file.type.split("/")[1],
        originalName: file.originalName,
        info: {
          width: null,
          height: null,
          size: file.size,
          format: file.type.split("/")[1],
        },
      };
      setProfileImage(reader.result);
      setLoading(true);
      let res = await callAPI("/image/upload", "POST", {
        body: { image: _file },
      });
      if (res.insertId) {
        let response = await callAPI("/user/updateUserProfileImage", "POST", {
          body: { ImageID: res.insertId },
        });
        if (response) {
          setupUserData(contextState, setLoading);
        }
      }
    };
    reader.onerror = function () {};
    return false;
  };

  const ProductBannerEnable = async () => {
    let shopproducts = await callAPI(`product/wishlist`, "GET");
    if (shopproducts.length) {
      setIsProductBannerEnable(false);
    }
  };

  useEffect(() => {
    if(Subpage?.toLowerCase() === "settings"){
      setStatusscroll(true)
    } else {
      navigate("/profile")
    }
  },[Subpage])

  useEffect(
    () => {
      !themeConfig.disableProfileProductBanner && ProductBannerEnable();
    },
    [],
    isProductBannerEnable
  );

  useEffect(() => {
    if (statusScroll) {
      setStatusscroll(false);
      setToggle(true);
      window.scrollBy(0, 500);
    }
  }, [statusScroll]);

  useEffect(() => {
     if (location?.state?.scrollToprofile === true){
     scrollToDiv("goto_profiletop");
    }
  }, []);

  useEffect(() => {
     if (location?.state?.scrollToStatus === true){
      statusRef?.current?.scrollIntoView({
        behavior: "smooth",
        // alignToTop: true,
      });
     }
     if (location?.state?.scrollToActivationSales === true && activationSalesRef){
      activationSalesRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
     }
     if (location?.state?.scrollToMyPoint === true && pointRef){
      pointRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [location?.state]);

  useEffect(() => {
    const sections = ["goto_profiletop", "menuBar"]
    if(!!toggle) return setScrollTargets([...sections, "footer"])

    if(!!brandData?.features?.enableWalletActivation) {
      if(!brandData?.features?.disableWalletActivationGridSection)
        sections.push("ActivationGridSection")
      if(!brandData?.features?.enableCorrectAdvanceStatement)
        sections.push("CorrectAdvanceStatement")
      sections.push("ActivationSales")
      if(!(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic))
        sections.push("StatisticsSection2")
      if(themeConfig.enableProfileStatusBlock)
        sections.push("StatusSection")
      if(!(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist))
        sections.push(isProductBannerEnable ? "GoToProductsBanner" : "WishlistBlock")
      if(brandData && brandData.features && brandData.features.profileMyCompetition)
        sections.push("CompetitionBlock")
      if(!themeConfig.disableOrder)
        sections.push("OrdersBlock")
      if(!brandData?.features?.disableProfilePointsOverview && !themeConfig.disablePoints)
        sections.push("PointOverview")
      if(!!brandData?.features?.Inbox)
        sections.push("InboxSection")
    } else {
      if(!!brandData?.features?.displayProfilePointGrid) 
        sections.push("PointGrid-points")
      if(!!brandData?.features?.displayProfilePointExpiryGrid) 
        sections.push("PointGrid-expiry")
      if(!!brandData?.features?.displayProfileTaxGrid) 
        sections.push("PointGrid-tax")
      if(!brandData?.features?.enableCorrectAdvanceStatement)
        sections.push("CorrectAdvanceStatement")
      if(!(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic)) 
        sections.push("StatisticSection")
      if(themeConfig.enableProfileStatusBlock) 
        sections.push("StatusSection")
      if(!(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist))
        sections.push(isProductBannerEnable ? "GoToProductsBanner" : "WishlistBlock")
      if(brandData && brandData.features && brandData.features.profileMyCompetition)
        sections.push("CompetitionBlock")
      if(!themeConfig.disableOrder)
        sections.push("OrdersBlock")
      if(brandData && brandData.features && brandData.features.enableWalletActivation)
        sections.push("ActivationSales")
      if(!brandData?.features?.disableProfilePointsOverview && !themeConfig.disablePoints) 
        sections.push("PointOverview")
      if(!!brandData?.features?.Inbox)
        sections.push("InboxSection")
    }

    sections.push("footer")
    setScrollTargets([...sections])
  },[brandData, themeConfig, toggle, document.documentElement.offsetHeight])

  return (
    <>
      <div
        id="goto_profiletop"
        ref={profileRef}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!!profileBannerImage ? (
          <>
            <Image
              src={`url(${profileBannerImage})`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                height: `${imageHeight}px`,
                width: "100%",
              }}
            >
              <div
                id="goto_profiletop"
                style={{
                  position: "absolute",
                  top: bannerImageHeight / 4,
                }}
              >
                <div className="profileBannerTitle">
                  <Headline3
                    style={{
                      fontSize: 60,
                      lineHeight: "60px",
                      color: styleGuide.color1,
                    }}
                  >
                    {profileBannerTitle}
                  </Headline3>
                </div>
              </div>
              <div
                className="menuTop"
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "rgba(34, 34, 34, 0.5)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Row xs={12}>
                  <Col md={4} xl={3}></Col>
                  <Col
                    xl={9}
                    style={{
                      paddingLeft: "80px",
                    }}
                  >
                    <Row xs={12}>
                      <StyledBottomNavigation showLabels>
                        <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_PROFILE_", "Profil")}
                          onClick={() => {
                            setToggle(false);
                            profileRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />
                        { !!brandData?.features?.enableWalletActivation && <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_ACTIVATION_SALES_", "Aktivering")}
                          onClick={() => {
                            setToggle(false);
                            activationSalesRef && activationSalesRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />}
                        {(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic) ? null : <StyledBottomNavigationAction
                          label={translate(
                            "_PROFILE_MENU_STATISTICS_",
                            "Statistik"
                          )}
                          onClick={() => {
                            setToggle(false);
                            staticRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />}
                        {themeConfig.enableProfileStatusBlock &&
                          <StyledBottomNavigationAction
                            label={translate(
                              "_PROFILE_MENU_STATUS_",
                              "Status"
                            )}
                            onClick={() => {
                              setToggle(false);
                              statusRef?.current?.scrollIntoView({
                                behavior: "smooth",
                                alignToTop: true,
                              });
                            }}
                          />
                        }
                        {(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist) ? null : <StyledBottomNavigationAction
                          label={translate(
                            "_PROFILE_MENU_WISHLIST_",
                            "Ønskeliste"
                          )}
                          onClick={() => {
                            setToggle(false);
                            if(wishListRef.current){
                              wishListRef?.current?.scrollIntoView({
                                behavior: "smooth",
                                alignToTop: true,
                              });
                            }
                          }}
                        />}
                        {brandData && brandData.features && brandData.features.profileMyCompetition && <StyledBottomNavigationAction
                          label={translate(
                            "_PROFILE_MENU_COMPETITIONS_",
                            "Konkurrencer"
                          )}
                          onClick={() => {
                            setToggle(false);
                            competitionRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />}
                        {!themeConfig.disableOrder && <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_ORDERS_", "Ordrer")}
                          onClick={() => {
                            setToggle(false);
                            orderRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />}
                        {brandData?.features?.disableProfilePointsOverview ? null : (!themeConfig.disablePoints && <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_POINTS_", "Point")}
                          onClick={() => {
                            setToggle(false);
                            pointRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />)}
                        {!!brandData.features?.Inbox && <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_INBOX_", "Indbakke")}
                          onClick={() => {
                            setToggle(false);
                            inboxRef?.current?.scrollIntoView({
                              behavior: "smooth",
                              alignToTop: true,
                            });
                          }}
                        />}
                      </StyledBottomNavigation>
                      <StyledBottomNavigation
                        style={{ margin: "auto" }}
                        showLabels
                      >
                         {(!!brandData?.features?.hideProfileMenuSetting || brandData?.features?.disableProfileStatistic) ? null : (<StyledBottomNavigationAction
                          label={translate(
                            "_PROFILE_MENU_SETTINGS_",
                            "Indstillinger"
                          )}
                          onClick={() => {
                            setToggle(true);
                          }}
                        />)}
                        <StyledBottomNavigationAction
                          label={translate("_PROFILE_MENU_LOGOUT_", "Log ud")}
                          onClick={() => setShowConfirmLogout(true)}
                          />
                      </StyledBottomNavigation>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Image>
          </>
        ) : null}
      </div>
      <div style={{ display: 'flex', position: "absolute", zIndex: 1, flexDirection: "column", gap: '1rem', top: `${imageHeight-100}px`}}>
        <Image className={classes.ProfileImageSize} style={{ backgroundColor: styleGuide.productInfoBackground }} src={`url(${profileImage}`}>
          <RoundIconButton1 onClick={openFileInput} icon={<Edit fill="white" />} 
            style={{
              position: "absolute",
              height: 60,
              width: 60,
              right: -30,
              top: -30
            }}
          />
        </Image>
        <input type="file" id="file" accept="image/*" ref={inputFile} style={{ display: "none" }} onChange={updateProfileImage} />
        <Headline4 fallbackText={userData.userName || ""} />
        <Headline6 fallbackText={userData.userCompany || ""} />
      </div>
      {enableProfileNote && <div className={classes.ProfileNote} style={isProfileNoteRow ? {top: `${imageHeight+100}px`, maxWidth: '500px', alignSelf: 'center'} : {top: `${imageHeight+250}px`, maxWidth: '500px', alignSelf: 'flex-start'}}>
        <span dangerouslySetInnerHTML={{__html: linkifyHtml(profileNote,{target: "_blank"})}} 
          style={{
            fontFamily: styleGuide.fontFamily,
            fontWeight: styleGuide.headline5FontWeight,
            fontSize: styleGuide.headline5FontSize,
            fontStyle: 'normal',
            lineHeight: styleGuide.headline5LineHeight,
            color: styleGuide.color7}}></span>
      </div>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: `${imageHeight}px`,
          // marginRight: 24,
        }}
      >
        <div className="menuBox" id="menuBar" style={!isProfileNoteRow ? {marginTop: '250px'} : {marginTop: '150px'}}>
          <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_PROFILE_"
            fallbackText="Profil"
            onClick={() => {
              setToggle(false);
              profileRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />
          {!!brandData?.features?.enableWalletActivation && <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_ACTIVATION_SALES_"
            fallbackText="Aktivering"
            onClick={() => {
              setToggle(false);
              if(activationSalesRef && activationSalesRef.current){
                activationSalesRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  alignToTop: true,
                });
              }
            }}
          />}
          {(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic) ? null : <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_STATISTICS_"
            fallbackText="Statistik"
            onClick={() => {
              setToggle(false);
              staticRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />}
          {themeConfig.enableProfileStatusBlock && 
          <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_STATUS_"
            fallbackText="Statistik"
            onClick={() => {
              setToggle(false);
              statusRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />}
          {(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist) ? null : <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_WISHLIST_"
            fallbackText="Ønskeliste"
            onClick={() => {
              setToggle(false);
              wishListRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />}
          {brandData && brandData.features && brandData.features.profileMyCompetition && <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_COMPETITIONS_"
            fallbackText="Konkurrencer"
            onClick={() => {
              setToggle(false);
              competitionRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />}
         {brandData?.features?.disableProfilePointsOverview ? null : (!themeConfig.disablePoints && <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_POINTS_"
            fallbackText="Point"
            onClick={() => {
              setToggle(false);
              pointRef?.current?.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
          />)}
          {!!brandData?.features?.Inbox && <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_INBOX_"
            fallbackText="Indbakke"
            onClick={() => {
              setToggle(false);
              if(inboxRef && inboxRef.current){
                inboxRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  alignToTop: true,
                });
              }
            }}
          />}
          {(!!brandData?.features?.hideProfileMenuSetting || brandData?.features?.disableProfileStatistic) ? null : <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_SETTINGS_"
            fallbackText="Indstillinger"
            onClick={() => {
              setToggle(true);
            }}
          />}
          <ContentNormal
            className="menuButton"
            translationKey="_PROFILE_MENU_LOGOUT_"
            fallbackText="Log ud"
            onClick={() => setShowConfirmLogout(true)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: enableProfileNote ? '100px' : '0px' }}>
          {!!brandData?.features?.enableWalletActivation ?
            <>
          {
          toggle 
            ? <MyProfile styleGuide={styleGuide} userData={userData} /> :
            <>
               
              <div style={{ marginTop: '100px' }}>
              {!brandData?.features?.disableWalletActivationGridSection && <ActivationGridSection id="ActivationGridSection" activateComplete={activateComplete} setActivateComplete={setActivateComplete} activationSalesRef={activationSalesRef} defaultShopID={defaultShopID} />}
              </div>
              <div>
                {!!brandData && !!brandData.features && !!brandData?.features?.enableCorrectAdvanceStatement && <CorrectAdvanceStatement id="CorrectAdvanceStatement" />}
              </div>
              {!!brandData?.features?.enableWalletActivation && <ActivationSales id="ActivationSales" activationSalesRef={activationSalesRef} setActivateComplete={setActivateComplete} />}
              {(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic) ? null : <StatisticsSection2 id="StatisticsSection2" staticRef={staticRef} activationSalesRef={activationSalesRef} setActivateComplete={setActivateComplete} />}
              {themeConfig.enableProfileStatusBlock && <StatusSection id="StatusSection" statusRef={statusRef} />}
              {(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist) ? null : <>
                {isProductBannerEnable ? (
                  <GoToProductsBanner id="GoToProductsBanner" wishListRef={wishListRef} customClass={classes.wishListMain}/>
                ) : (
                  <>
                    <div
                      id="WishlistBlock"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        // marginBottom: 100,
                        position: "relative"
                      }}
                      className={classes.wishListMain}
                    >
                      <div
                        ref={wishListRef}
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <Headline2
                          translationKey="_PROFILE_PAGE_YOUR_WISHLIST_"
                          fallbackText="Din ønskeliste"
                        />
                      </div>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <SingleWishlistProduct
                          isProductBannerEnable={setIsProductBannerEnable}
                        />
                      </div>
                      <div className={classes.seeMoreButton}>
                        <ButtonLarge4
                          showIcon={false}
                          translationKey="_PROFILE_PAGE_SEE_ALL_PRODUCTS_"
                          fallbackText="Se alle produkter"
                          onClick={() => navigate(`/shop/${defaultShopID}`)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>}
              {brandData && brandData.features && brandData.features.profileMyCompetition && <CompetitionBlock id="CompetitionBlock" competitionRef={competitionRef} />}
              {!themeConfig.disableOrder && <OrdersBlock id="OrdersBlock" orderRef={orderRef} />}
              {brandData?.features?.disableProfilePointsOverview ? null : (!themeConfig.disablePoints && <PointOverview id="PointOverview" activateComplete={activateComplete} setActivateComplete={setActivateComplete} pointRef={pointRef} />)}
              {!!brandData?.features?.Inbox && <InboxSection id="InboxSection" inboxRef={inboxRef} />}
            </>
          }
            </> :
            <>
            {
          toggle 
            ? <MyProfile styleGuide={styleGuide} userData={userData} />
            : <>
              {
                !!brandData?.features?.displayProfilePointGrid && <PointGrid id="PointGrid-points" activateComplete={activateComplete} setActivateComplete={setActivateComplete} variant="points" />
              }
              {
                !!brandData?.features?.displayProfilePointExpiryGrid && <PointGrid id="PointGrid-expiry" activateComplete={activateComplete} setActivateComplete={setActivateComplete} variant="expiry" />
              }
              {
                !!brandData?.features?.displayProfileTaxGrid && <PointGrid id="PointGrid-tax" activateComplete={activateComplete} setActivateComplete={setActivateComplete} variant="tax" />
              }
              
              {(themeConfig.disableStatistic || brandData?.features?.disableProfileStatistic) ? null : <StatisticSection id="StatisticSection" staticRef={staticRef} />}
              {themeConfig.enableProfileStatusBlock && <StatusSection id="StatusSection" statusRef={statusRef} />}
              {(themeConfig.disableWishlist || brandData?.features?.disableProfileWishlist) ? null : <>
                {isProductBannerEnable ? (
                  <GoToProductsBanner id="GoToProductsBanner"  wishListRef={wishListRef} customClass={classes.wishListMain}/>
                ) : (
                  <>
                    <div
                      id="WishlistBlock"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        // marginBottom: 100,
                        position: "relative"
                      }}
                      className={classes.wishListMain}
                    >
                      <div
                        ref={wishListRef}
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <Headline2
                          translationKey="_PROFILE_PAGE_YOUR_WISHLIST_"
                          fallbackText="Din ønskeliste"
                        />
                      </div>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <SingleWishlistProduct
                          isProductBannerEnable={setIsProductBannerEnable}
                        />
                      </div>
                      <div className={classes.seeMoreButton}>
                        <ButtonLarge4
                          showIcon={false}
                          translationKey="_PROFILE_PAGE_SEE_ALL_PRODUCTS_"
                          fallbackText="Se alle produkter"
                          onClick={() => navigate(`/shop/${defaultShopID}`)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>}
              <KAMDashboard/>
              {brandData && brandData.features && brandData.features.profileMyCompetition && <CompetitionBlock id="CompetitionBlock" competitionRef={competitionRef} />}
              {!themeConfig.disableOrder && <OrdersBlock id="OrdersBlock" orderRef={orderRef} />}
              {brandData && brandData.features && brandData.features.enableWalletActivation && <ActivationSales id="ActivationSales" activationSalesRef={activationSalesRef} setActivateComplete={setActivateComplete} />}
              {brandData?.features?.disableProfilePointsOverview ? null : (!themeConfig.disablePoints && <PointOverview id="PointOverview" activateComplete={activateComplete} setActivateComplete={setActivateComplete} pointRef={pointRef} />)}
              {!!brandData?.features?.Inbox && <InboxSection id="InboxSection" inboxRef={inboxRef} />}
            </>
          }
          </>}
        </div>
      </div>
      
      <Modal
        show={showConfirmLogout}
        onHide={() => setShowConfirmLogout(false)}
        style={{ margin: "auto" }}
        centered
      >
        <Modal.Header
          className="pb-0 border-0"
          closeButton
          style={{ fontSize: 9 }}
        >
          <Headline6 translationKey="_CONFIRM_LOGOUT_">
            Bekræft log ud{" "}
          </Headline6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ContentSmall translationKey="_CONFIRM_LOGOUT_TEXT_">
            Er du sikker på at du vil logge ud?{" "}
          </ContentSmall>
          <div
            style={{
              display: "flex",
              padding: "1rem",
              justifyContent: "space-around",
            }}
          >
            <ButtonMedium1
              showIcon={false}
              translationKey="_YES_"
              fallbackText="Ja"
              onClick={() => clearSession()}
            />
            <ButtonMedium4
              showIcon={false}
              translationKey="_NO_"
              fallbackText="Nej"
              onClick={() => setShowConfirmLogout(false)}
            />
          </div>
        </Modal.Body>
      </Modal>

      <ScrollButton scrollTargets={[...scrollTargets]} />
    </>
  );
};

export const MyProfile = (props) => {
  const context = useContext(AppContext);
  const { userData, styleGuide, brandData } = context;
  const hideNotificationPreference = brandData?.features?.hideNotificationPreferenceEditProfile || false
  const enableMobileNumberUpdates = brandData?.features?.enableMobileNumberUpdates || false
  const getCountryCodeOfPhoneNumber = (phoneNumber = "") => {
    if(!phoneNumber || !phoneNumber.includes("+")){
      return ""
    }
    const allowedCountryCodes = ["+29","+35","+44","+45","+46","+47","+49"]
    for (const code of allowedCountryCodes) {
      if(phoneNumber.includes(code)) return code
    }
    return ""
  }
  const countryCode = enableMobileNumberUpdates ? brandData?.features?.mobileNumberCountryCode || "+45" : getCountryCodeOfPhoneNumber(userData?.userPhone) || ""
  const mobileNumberLength = 8
  const userPhone = userData?.userPhone?.split(countryCode)
  const [ userMobileNumber, setUserMobileNumber ] = useState(userPhone[1] || "")
  const [loading, setLoading] = useState(false);
  const [showProfileUpdated, setShowProfileUpdated] = useState(false);
  const [showMobileNumberError, setShowMobileNumberError] = useState(false)
  const isSantanderKAM = brandData?.BrandID === 63
  const [userProfile, setUserProfile] = useState(userData)

  const handleChange = (mobileNumberInput="") => {
    setUserMobileNumber(mobileNumberInput)
  }

  const updateUserMobileNumber = async() => {
    let mobileNumberInput = userMobileNumber
    if(!userMobileNumber.startsWith(countryCode)) {
      mobileNumberInput = `${countryCode}${mobileNumberInput}`
    }
    const response = await callAPI("/user/updateUserMobileNumber", "PATCH", {body: {userMobileNumber: mobileNumberInput}})
    if(response === true) {
      setupUserData(context, setLoading)
      setTimeout(() => {
        setShowProfileUpdated(true);
      },1000)
    }
  }

  const updateUserProfile = async() => {
    setLoading(true)
    const response = await callAPI("/user/updateUserProfile", "POST", {body: userProfile})
    setLoading(false)
    if(response === true) {
      setupUserData(context, setLoading)
      setTimeout(() => {
        setShowProfileUpdated(true);
      },1000)
    }
  }

  const ProfileUpdate = () => (
    <Modal show={showProfileUpdated} onHide={() => setShowProfileUpdated(false)} style={{ margin: 'auto' }} centered>
      <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
        <Headline6 translationKey="_PROFILE_UPDATE_">Opdateret</Headline6>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <ContentSmall translationKey="_PROFILE_UPDATE_TEXT_">Profil er opdateret</ContentSmall>
        <div style={{ display: 'flex', float: 'right', padding: '1rem' }}>
          <ButtonMedium1 showIcon={false} translationKey="_OK_" fallbackText="OK" onClick={() => setShowProfileUpdated(false)} />
        </div>
      </Modal.Body>
    </Modal>
  )

  const updateCommercialSMSConsent = async() => {
    await callAPI("/user/updateCommercialSMSConsent", "POST")
    setupUserData(context, setLoading)
    setTimeout(() => {
      setShowProfileUpdated(true);
    },1000)
  }

  const updateCommercialEmailConsent = async() => {
    await callAPI("/user/updateCommercialEmailConsent", "POST")
    setupUserData(context, setLoading)
    setTimeout(() => {
      setShowProfileUpdated(true);
    },1000)
  }

  useEffect(() => {
    if(!enableMobileNumberUpdates) return
    const isValid = isValidPhoneNumber(countryCode, mobileNumberLength, userMobileNumber)
    setShowMobileNumberError(!isValid)
  },[userMobileNumber])

  return (
    <div id={props?.id || ""} style={{width: '100%', maxWidth: 768, marginTop: 150}}>
      <Row xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 40,
            marginTop: 42,
            // marginBottom: 100,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Headline2
              translationKey="_PROFILE_MIN_PROFILE_"
              fallbackText="Min profil"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 30,
              marginBottom: 32,
            }}
          >
            <CustomInput
              style={{ paddingTop: 10, color: styleGuide.color3 }}
              type="type"
              label={translate("_PROFILE_EDIT_FIELD_LABEL_NAME_", "Navn")}
              value={userProfile.userName}
              onChange={(e) => setUserProfile({...userProfile, userName: e.target.value})}
              disabled = {!isSantanderKAM}
            />
            <div
              className="settingInputbox"
              style={{ display: "flex", gap: 40, width: "100%" }}
            >
              <span style={{ flex: 1 }}>
                <CustomInput
                  style={{ paddingTop: 10 }}
                  label={translate("_PROFILE_EDIT_FIELD_LABEL_TELEPHONE_", "Telefon")}
                  value={userMobileNumber}
                  onChange={(e) => handleChange(e.target.value)}    
                  disabled={!enableMobileNumberUpdates}
                  error={showMobileNumberError}
                  prefix={countryCode}
                  helpObj={{
                    errorText: translate(
                      "_PROFILE_INVALID_TELEPHONE_TEXT_" , 
                      "Mobile number should be 8 digits which is automatically prefixed by Country code"
                    )
                  }}
                />
              </span>
              <span style={{ flex: 1 }}>
                <CustomInput
                  style={{ paddingTop: 10 }}
                  label={translate("_PROFILE_EDIT_FIELD_LABEL_EMAIL_", "E-mail")}
                  value={userProfile.userEmail}
                  onChange={(e) => setUserProfile({...userProfile, userEmail: e.target.value})}
                  disabled = {!isSantanderKAM}
                />
              </span>
            </div>
            {!!enableMobileNumberUpdates && <ButtonMedium1
              style={{width: 100}}
              showIcon={false}
              translationKey="_BUTTON_CAPTION_SAVE_"
              fallbackText="Godkend"
              onClick={() => updateUserMobileNumber()}
              disabled={showMobileNumberError}
            />}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Headline3
              translationKey="_PROFILE_COMPANY_INFORMATION_"
              fallbackText="Firmaoplysninger"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 30,
              marginBottom: 32,
            }}
          >
            <CustomInput
              style={{ paddingTop: 10 }}
              label={translate("_PROFILE_EDIT_FIELD_LABEL_COMPANY_", "Firma")}
              value={userProfile.userCompany}
              onChange={(e) => setUserProfile({...userProfile, userCompany: e.target.value})}
              disabled = {!isSantanderKAM}
            />
            <CustomInput
              style={{ paddingTop: 10 }}
              label={translate("_PROFILE_EDIT_FIELD_LABEL_COMPANY_ADDRESS_", "Firmaadresse")}
              value={userProfile.userAddress}
              onChange={(e) => setUserProfile({...userProfile, userAddress: e.target.value})}
              disabled = {!isSantanderKAM}
            />
            <div
              className="settingInputbox"
              style={{ display: "flex", gap: 40, width: "100%" }}
            >
              <span style={{ flex: 1 }}>
                <CustomInput
                  style={{ paddingTop: 10 }}
                  label={translate("_PROFILE_EDIT_FIELD_LABEL_ZIPCODE_", "Postnummer")}
                  value={userProfile.userZipcode}
                  onChange={(e) => setUserProfile({...userProfile, userZipcode: e.target.value})}
                  disabled = {!isSantanderKAM}
                />
              </span>
              <span style={{ flex: 1 }}>
                <CustomInput
                  style={{ paddingTop: 10 }}
                  label={translate("_PROFILE_EDIT_FIELD_LABEL_CITY_", "By")}
                  value={userProfile.userCity}
                  onChange={(e) => setUserProfile({...userProfile, userCity: e.target.value})}
                  disabled = {!isSantanderKAM}
                />
              </span>
            </div>
            {!!isSantanderKAM && <ButtonMedium1
              style={{width: 100}}
              showIcon={false}
              translationKey="_BUTTON_CAPTION_SAVE_"
              fallbackText="Godkend"
              onClick={() => updateUserProfile()}
              disabled={!!loading}
            />}
          </div>

          {/* Notification Consent settings */}
          <>
            <Headline3 translationKey="_PROFILE_NOTIFICATION_CONSENT_TITLE_" fallbackText="Notifikationer" />
            <ContentLarge translationKey="_PROFILE_NOTIFICATION_CONSENT_TEXT_" fallbackText="Jeg ønsker at modtage følgende notifikationer" />
            <ContentLarge translationKey="_PROFILE_NOTIFICATION_CONSENT_NECESSARY_TEXT_" fallbackText="Nødvendige beskeder så som adgangskoder, ordre, point udløb mv." />
            <div style={{ display: "flex", gap: "2rem" }}>
              <CustomCheckbox
                disabled
                checked={true}
                label={translate("_SMS_", "SMS")}
              />
              <CustomCheckbox
                disabled
                checked={true}
                label={translate("_EMAIL_", "Email")}
              />
            </div>
            {
              !hideNotificationPreference && <>
                <ContentLarge translationKey="_PROFILE_NOTIFICATION_CONSENT_COMMERCIAL_TEXT_" fallbackText="Kommercielle beskeder som nyheder, ”lagersalg”, nye produkter mv." />
                <div style={{ display: "flex", gap: "2rem" }}>
                  <CustomCheckbox
                    checked={!!userData?.consentNews?.SMS}
                    label={translate("_SMS_", "SMS")}
                    onChange={(e) => {
                      updateCommercialSMSConsent(e.target.checked)
                    }}
                  />
                  <CustomCheckbox
                    checked={!!userData?.consentNews?.EMAIL}
                    label={translate("_EMAIL_", "Email")}
                    onChange={(e) => {
                      updateCommercialEmailConsent(e.target.checked)
                    }}
                  />
                </div>
              </>
            }
          </>
        </div>
      </Row>
      <ProfileUpdate/>
    </div>
  );
};

export const CompetitionBlock = (props) => {
  const {competitionRef} = props
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;

  const NoCompetition = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        border: `1px solid ${styleGuide.color4}`,
        borderRadius: "14px",
        padding: 80,
      }}
    >
      <Headline4
        translationKey="_PROFILE_PAGE_NO_COMPETITION_CAPTION_"
        fallbackText="Du har ingen konkurrencer endnu"
      />
    </div>
  );

  const [competitions, setCompetitions] = useState([])
  const [cancelLoading, setCancelLoading] = useState(false)
  
  const fetchCompetition = async () => {
    setCancelLoading(true)
    const userCompetitions = await callAPI(`/competition/mine`);
    setCompetitions(userCompetitions)
  }

  const cancelCompetition = async (CompetitionID) => {
    const cancel = await callAPI(`/competition/${CompetitionID}/cancel`, 'POST');
    if(cancel.protocol41) {
      fetchCompetition()
      setCancelLoading(false)
    }
  }

  useEffect(() => {
    fetchCompetition()
  },[])

  return (
    <div id={props?.id || ""} ref={competitionRef} style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Headline2 translationKey="_PROFILE_PAGE_COMPETITION_" fallbackText="Dine konkurrencer" />
      </div>
      {!competitions ? null : !!competitions && !competitions.length ? (
          <NoCompetition />
        ) : (
          competitions.length < 2 ?
          <>
            {competitions.map((competition, idx) => (
              <CountDownBlock key={idx} competition={competition} styles={{marginTop: 50}} buttonTitle="Læs mere" cancelButton={true} cancelLoading={cancelLoading} cancelButtonFunc={()=>{cancelCompetition(competition.CompetitionID)}} />
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {competitions.map((competition, idx) => (
                <CountDownBlock key={idx} competition={competition} buttonTitle="Læs mere" cancelButton={true} cancelLoading={cancelLoading} cancelButtonFunc={()=>{cancelCompetition(competition.CompetitionID)}} />
              ))}
            </HorizontalScrollBlock>
          </div>
      )}
    </div>
  )
}

export const OrdersBlock = (props) => {
  const thisYear = new Date().getUTCFullYear();
  const { styleGuide, userData, brandData } = useContext(AppContext);
  const [orders, setOrders] = useState(null);
  const [allOrders, setAllOrders] = useState(null);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const checkEmailText = translate(
    "_PROFILE_PAGE_TAX_VALIDATE_EMAIL_",
    "Please provide a valid email"
  );
  const emailSuccessText = translate(
    "_PROFILE_PAGE_TAX_SUCCESS_",
    "Skattelisten er sendt pr mail til den indtastede adresse."
  );
  const emailFailureText = translate(
    "_PROFILE_PAGE_TAX_FAILURE_",
    "Something went wrong, please try again later"
  );

  const yearOptions = [
    thisYear - 1,
    thisYear - 2,
    thisYear - 3,
    thisYear - 4,
    thisYear - 5,
  ].map((x) => {
    return { value: x, label: x };
  });

  const classes = jss(styleGuide);

  const fetchOrders = async () => {
    const userOrderLines = await callAPI(`/user/orders`);
    const userOrders = [];
    if (!userOrderLines || !userOrderLines.length) return setOrders([]);

    for (const orderLine of userOrderLines) {
      const translationKey = !!orderLine.orderFlowStatus ? `_ORDER_STATUS_${orderLine.orderFlowStatus}_` : null
      const orderObj = {
        OrderID: orderLine.OrderID,
        points: orderLine.orderAmountPoints,
        orderFlowStatus: orderLine.orderFlowStatus || '-',
        orderType	: orderLine.orderType	 || '',
        orderFlowStatusTranslationKey: translationKey || orderLine.orderFlowStatus,
        orderRemark: orderLine.userOrderRemarks,
        alertCount: orderLine.alertCount,
        date: moment(orderLine.timeInsert).format("DD.MM.YYYY"),
        track: orderLine.orderGlsCustomerNumber,
        orderConsignee: orderLine.orderConsignee,
        orderConsigneeName: orderLine.orderConsigneeName,
        orderConsigneeAddress1: orderLine.orderConsigneeAddress1,
        orderConsigneeAddress2: orderLine.orderConsigneeAddress2,
        orderConsigneeZipcode: orderLine.orderConsigneeZipcode,
        orderConsigneeCity: orderLine.orderConsigneeCity,
        orderConsigneeCountry: orderLine.orderConsigneeCountry,
        orderConsigneePhone: orderLine.orderConsigneePhone,
        orderConsigneeEmail: orderLine.orderConsigneeEmail,
        orderConsigneeCustomerNumber: orderLine.orderConsigneeCustomerNumber || '',
        freightPoints: orderLine.freightPoints || 0,
        dropshipPoints: orderLine.dropshipPoints || 0,
        totalFreightPoints: (orderLine.freightPoints || 0) + ( orderLine.dropshipPoints || 0),
        orderLines: [
          {
            OrderlineID: orderLine.OrderlineID,
            VariationID: orderLine.VariationID,
            ProductID: orderLine.ProductID,
            imageUrl: orderLine.images[0]
              ? orderLine.images[0].imageOriginSource
              : "",
            elementTitle: orderLine.elementTitle,
            manufacturer: orderLine.manufacturer,
            points: orderLine.orderAmountPoints,
            variationTitle: orderLine.variationTitle,
            variationSize: orderLine.variationSize,
            variationColor: orderLine.colorName,
            translations: orderLine.translations,
            quantity: 1,
          },
        ],
      };
      const existingEntry = userOrders.find(
        (item) => item.OrderID === orderLine.OrderID
      );
      if (!existingEntry || !existingEntry.OrderID) {
        userOrders.push(orderObj);
        continue;
      }
      const existingEntryIndex = userOrders
        .map((item) => item.OrderID)
        .indexOf(orderLine.OrderID);
      existingEntry.points += orderObj.points;
      const existingVariationEntryIndex = existingEntry.orderLines
        .map((item) => item.VariationID)
        .indexOf(orderLine.VariationID);

      if (existingVariationEntryIndex < 0) {
        existingEntry.orderLines.push(...orderObj.orderLines);
      } else {
        existingEntry.orderLines[existingVariationEntryIndex].quantity =
          1 + existingEntry.orderLines[existingVariationEntryIndex].quantity;
        existingEntry.orderLines[existingVariationEntryIndex].points +=
          existingEntry.orderLines[existingVariationEntryIndex].points;
      }

      userOrders[existingEntryIndex] = existingEntry;
    }
    userOrders.sort((a, b) => b.OrderID - a.OrderID);
    setAllOrders(userOrders);
    return setOrders(userOrders.slice(0, 5));
  };

  useEffect(() => {
    fetchOrders();
  },[])

  useEffect(() => {
    if (!showTaxPopup) {
      // setUserEmail(userData.userEmail);
      // setSendTaxSuccess("");
      // setSendTaxFailure("");
    }
  }, [showTaxPopup]);

  const NoOrders = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        border: `1px solid ${styleGuide.color4}`,
        borderRadius: "14px",
        padding: 40,
      }}
    >
      <Headline4
        translationKey="_PROFILE_PAGE_NO_ORDERS_CAPTION_"
        fallbackText="Du har ingen ordrer endnu"
      />
      <ContentLarge
        translationKey="_PROFILE_PAGE_NO_ORDERS_TEXT_"
        fallbackText="Når du laver dine første ordre kan du følge den her. "
      />
    </div>
  );

  const TaxModal = () => {
    const [userEmail, setUserEmail] = useState(userData.userEmail || "");
    const [taxYear, setTaxYear] = useState(thisYear - 1);
  const [sendTaxSuccess, setSendTaxSuccess] = useState("");
  const [sendTaxFailure, setSendTaxFailure] = useState("");

  const sendTaxDetails = async () => {
    setSendTaxFailure("");
    setSendTaxSuccess("");

    if (!userEmail || !isValidEmail(userEmail))
      return setSendTaxFailure(checkEmailText);

    const response = await callAPI("/profile/sendTaxDetails", "POST", {
      body: { year: taxYear, userEmail },
    });

    if (
      !!response &&
      !!response.message &&
      response.message.toUpperCase() === "SUCCESS"
    ) {
      return setSendTaxSuccess(emailSuccessText);
    } else {
      return setSendTaxFailure(emailFailureText);
    }
  };

  return <Modal
      show={showTaxPopup}
      onHide={() => setShowTaxPopup(false)}
      style={{ margin: "auto" }}
      centered
    >
      <Modal.Header
        className="pb-0 border-0"
        closeButton
        style={{ fontSize: 9 }}
      >
        <Headline6
          translationKey="_PROFILE_PAGE_TAX_POPUP_CAPTION_"
          fallbackText="Email til skattedetaljer"
        />
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <ContentSmall
            translationKey="_PROFILE_PAGE_TAX_POPUP_TEXT_"
            fallbackText="Få tilsendt en samlet oversigt over dine præmiebestillinger med en vejledende skatteværdi."
          />
          <CustomInput
            wrapperStyle={{ marginBottom: 10 }}
            label={translate("_PROFILE_PAGE_TAX_POPUP_EMAIL_FIELD_", "Email")}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <CustomSelect
            wrapperStyle={{ marginBottom: 10 }}
            label={translate("_PROFILE_PAGE_TAX_POPUP_YEAR_FIELD_", "År")}
            value={taxYear}
            onChange={(e) => setTaxYear(e.target.value)}
            options={yearOptions}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {!!sendTaxSuccess && (
            <CaptionText
              style={{ color: styleGuide.color8 }}
              fallbackText={sendTaxSuccess}
            />
          )}
          {!!sendTaxFailure && (
            <CaptionText
              style={{ color: styleGuide.color13 }}
              fallbackText={sendTaxFailure}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <ButtonMedium1
          showIcon={false}
          translationKey="_BUTTON_CAPTION_SAVE_"
          fallbackText="Godkend"
          onClick={() => sendTaxDetails()}
        />
        <ButtonMedium4
          showIcon={false}
          translationKey="_BUTTON_CAPTION_CLOSE_"
          fallbackText="Luk"
          onClick={() => setShowTaxPopup(false)}
        />
      </Modal.Footer>
    </Modal>
  }

  return (
    <>
      <TaxModal />
      <div
        className={classes.OrderBlock}
        id={props?.id || ""}
        ref={props.orderRef}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          // marginTop: 100,
          // marginBottom: 100,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }} className={"orderHead"}>
          <Headline2
            translationKey="_PROFILE_PAGE_YOUR_ORDERS_"
            fallbackText="Dine ordrer"
          />
          {!!brandData && !!brandData.features && !brandData.features.hideProfileTaxReportButton && <ButtonLarge4
            showIcon={false}
            translationKey="_PROFILE_PAGE_TAX_BUTTON_TEXT_"
            fallbackText="Oversigt for indberetning til SKAT"
            onClick={() => setShowTaxPopup(true)}
            style={{width:'max-content'}}
          />}
        </div>
        {!orders ? null : !!orders && !orders.length ? (
          <NoOrders />
        ) : (
          <>
            {orders.map((order, idx) => (
              <Order key={idx} order={order} refresh={fetchOrders}/>
            ))}
            {!!orders &&
              !!orders.length &&
              orders.length != allOrders.length && (
                <ButtonLarge1
                  showIcon={false}
                  translationKey="_ORDERS_SEE_ALL_"
                  fallbackText="Se alle ordre"
                  style={{ maxWidth: 121, alignSelf: "center" }}
                  onClick={() => setOrders(allOrders)}
                />
              )}
          </>
        )}
      </div>
    </>
  );
};

export const InboxSection = (props) => {
  const { styleGuide } = useContext(AppContext);
  const [messages, setMessages] = useState(null);
  const [allMessages, setAllMessages] = useState(null);

  const fetchMessages = async () => {
    const userMessagesLines = await callAPI(`/user/inbox`);
    const userMessages = [];
    if (!userMessagesLines || !userMessagesLines.length) return setMessages([]);

    for (const messageLine of userMessagesLines) {
      const messageObj = {
        date: moment(messageLine.timeInsert).format("DD.MM.YYYY"),
        communicationChannel: messageLine.communicationChannel,
        messageRecipient: messageLine.communicationRecipient,
        messageSender: messageLine.communicationSender,
        messageSubject: messageLine.communicationSubject,
        messagePlainText: messageLine.communicationMessagePlainText,
      };
      userMessages.push(messageObj);
    }
    setAllMessages(userMessages);
    return setMessages(userMessages.slice(0, 5));
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const NoMessages = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        border: `1px solid ${styleGuide.color4}`,
        borderRadius: "14px",
        padding: 40,
      }}
    >
      <Headline4
        translationKey="_PROFILE_PAGE_NO_MESSAGES_CAPTION_"
        fallbackText="Du har ingen beskeder endnu"
      />
      <ContentLarge
        translationKey="_PROFILE_PAGE_NO_MESSAGES_TEXT_"
        fallbackText="Når du modtager en besked, vil du se den her. "
      />
    </div>
  );

  return (
    <>
      <div
        id={props?.id || ""}
        ref={props.inboxRef}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginTop: 100,
          // marginBottom: 100,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Headline2
            translationKey="_PROFILE_PAGE_INBOX_"
            fallbackText="Din indbakke"
          />
        </div>
        {!messages ? null : !!messages && !messages.length ? (
          <NoMessages />
        ) : (
          <>
            {messages.map((message, idx) => (
              <Message key={idx} message={message} />
            ))}
            {!!messages &&
              !!messages.length &&
              messages.length != allMessages.length && (
                <ButtonLarge1
                  showIcon={false}
                  translationKey="_MESSAGES_SEE_ALL_"
                  fallbackText="Se alle beskeder"
                  style={{ maxWidth: 150, alignSelf: "center" }}
                  onClick={() => setMessages(allMessages)}
                />
              )}
          </>
        )}
      </div>
    </>
  );
};

export const PointGrid = (props) => {
  const {variant, activateComplete, setActivateComplete, id} = props
  const { styleGuide, langCode, brandData } = useContext(AppContext);
  const pointsGridLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.pointsGridLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.pointsGridLabels : null
  const [points, setPoints] = useState({
    largeBoxValue: '0',
    topLeftBoxValue: '0',
    topRightBoxValue: '0',
    bottomLeftBoxValue: '0',
    bottomRightBoxValue: '0'
  });
  const [labels, setLabels] = useState({
    largeBoxValue: {translationKey: null, fallbackText: ' '},
    topLeftBoxValue: {translationKey: null, fallbackText: ' '},
    topRightBoxValue: {translationKey: null, fallbackText: ' '},
    bottomLeftBoxValue: {translationKey: null, fallbackText: ' '},
    bottomRightBoxValue: {translationKey: null, fallbackText: ' '}
  });
  const prepareData = async (variant) => {
    let response;
    setActivateComplete({...activateComplete, pointBox: false})
    switch (variant) {
      case 'expiry':
        response = await callAPI("/user/pointsSummary", "GET");
        response = response[0]
        if (response) {
          setPoints({
            largeBoxValue: response?.total_earn > 0 ? response.total_earn : '0',
            topLeftBoxValue: response?.running_out > 0 ? response.running_out : '0',
            topRightBoxValue: response?.running_out_6m > 0 ? response.running_out_6m : '0',
            bottomLeftBoxValue: response?.running_out_3m > 0 ? response.running_out_3m : '0',
            bottomRightBoxValue: response?.running_out_beyond_6m > 0 ? response.running_out_beyond_6m : '0',
          });
        }
        return setLabels({
          largeBoxValue: {translationKey: "_PROFILE_POINTSGRID_TOTAL_POINTS_", fallbackText:  "Dine totale point"},
          topLeftBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_EXPIRE_CURRENT_MONTH_', fallbackText:  'Udløber i denne måned'},
          topRightBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_EXPIRE_A_MONTH_', fallbackText:  'Udløber om 1 måned'},
          bottomLeftBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_EXPIRE_3_MONTH_', fallbackText:  'Udløber om 3 måneder'},
          bottomRightBoxValue:  {translationKey: '_PROFILE_POINTSGRID_POINTS_EXPIRE_6_MONTH_', fallbackText:  'Udløber om mere end 6 måneder'}
        });

      case 'tax':
        response = await callAPI("/user/getProfileTaxStats", "GET");
        setPoints({
          largeBoxValue: response?.totalReceived || '0',
          topLeftBoxValue: response?.totalReceivedThisYear || '0',
          topRightBoxValue: formatNumber(response?.totalReceivedValueThisYear || '0', langCode),
          bottomLeftBoxValue: response?.totalSpentPointsThisYear || '0',
          bottomRightBoxValue: formatNumber(response?.totalSpentValueThisYear || '0', langCode),
        });        
        return setLabels({
          largeBoxValue: {translationKey: "_PROFILE_POINTSGRID_TOTAL_POINTS_", fallbackText:  "Dine totale point"},
          topLeftBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_RECEIVED_THIS_YEAR_', fallbackText:  'År til dato'},
          topRightBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_RECEIVED_TAX_THIS_YEAR_', fallbackText:  'Værdi af point år til dato'},
          bottomLeftBoxValue: {translationKey: '_PROFILE_POINTSGRID_POINTS_USED_THIS_YEAR_', fallbackText:  'Køb i point år til dato'},
          bottomRightBoxValue:  {translationKey: '_PROFILE_POINTSGRID_POINTS_USED_TAX_THIS_YEAR_', fallbackText:  'Værdi af køb år til dato'}
        });

      case 'points':
      default:
        response = await callAPI("/user/getProfileWalletStats", "GET");
        setPoints({
          largeBoxValue: response?.totalReceived || '0',
          topLeftBoxValue: response?.totalReceivedThisMonth || '0',
          topRightBoxValue: response?.totalReceivedLastMonth || '0',
          bottomLeftBoxValue: response?.totalReceivedThisYear || '0',
          bottomRightBoxValue: response?.totalReceivedLastYear || '0',
        });
        return setLabels({
          largeBoxValue: {configuredLabel: pointsGridLabels?.totalPointsLabel, translationKey:  "_PROFILE_POINTSGRID_TOTAL_POINTS_", fallbackText:  "Dine totale point"},
          topLeftBoxValue: {configuredLabel: pointsGridLabels?.currentMonthPointsLabel, translationKey:  '_PROFILE_POINTSGRID_POINTS_CURRENT_MONTH_', fallbackText:  'Indeværende måned'},
          topRightBoxValue: {configuredLabel: pointsGridLabels?.lastMonthPointsLabel, translationKey: '_PROFILE_POINTSGRID_POINTS_LAST_MONTH_', fallbackText:  'Sidste måned'},
          bottomLeftBoxValue: {configuredLabel: pointsGridLabels?.thisYearPointsLabel, translationKey: '_PROFILE_POINTSGRID_POINTS_THIS_YEAR_', fallbackText:  'År til dato'},
          bottomRightBoxValue:  {configuredLabel: pointsGridLabels?.lastYearPointsLabel, translationKey: '_PROFILE_POINTSGRID_POINTS_LAST_YEAR_', fallbackText:  'Sidste år'}
        });
    }
  }

  useEffect(() => {
      prepareData(variant)
  }, [variant]);

  useEffect(() => {
    activateComplete && activateComplete.pointBox && prepareData(variant)
  }, [variant, activateComplete]);

  return (
    <div id={id || ""} style={{width: '100%', maxWidth: 768, alignSelf: 'flex-end',...props.styles}}>
      <Row xs={12}>
        <Col xs={4} className="gridCol">
          <div
            className="pointsGridOne"
            style={{ backgroundColor: styleGuide.color16 }}
          >
            <Headline3 style={{ color: styleGuide.color1 }}>
              {points.largeBoxValue || "0"}
            </Headline3>
            <p
              style={{
                color: styleGuide.color1,
                fontSize: styleGuide.captionFontSize,
              }}
            >
              {labels.largeBoxValue.configuredLabel ? labels.largeBoxValue.configuredLabel : translate(labels.largeBoxValue.translationKey, labels.largeBoxValue.fallbackText)}
            </p>
          </div>
        </Col>
        <Col xs={8} className="gridCol">
          <Row xs={12}>
            <Col xs={6} className="gridCol">
              <div
                className="pointsGridTwo"
                style={{ backgroundColor: styleGuide.color2 }}
              >
                <Headline3
                  style={{ color: styleGuide.color7 }}
                >{points.topLeftBoxValue || '0'}</Headline3>
                <p
                  style={{
                    color: styleGuide.color6,
                    fontSize: styleGuide.explainerTextTinyFontSize,
                  }}
                >
                  {labels.topLeftBoxValue.configuredLabel ? labels.topLeftBoxValue.configuredLabel : translate(labels.topLeftBoxValue.translationKey, labels.topLeftBoxValue.fallbackText)}
                </p>
              </div>
            </Col>
            <Col xs={6} className="gridCol">
              <div
                className="pointsGridTwo"
                style={{ backgroundColor: styleGuide.color2 }}
              >
                <Headline3
                  style={{ color: styleGuide.color7 }}
                >{ points.topRightBoxValue || '0'}</Headline3>
                <p
                  style={{
                    color: styleGuide.color6,
                    fontSize: styleGuide.explainerTextTinyFontSize,
                  }}
                >
                  {labels.topRightBoxValue.configuredLabel ? labels.topRightBoxValue.configuredLabel : translate(labels.topRightBoxValue.translationKey, labels.topRightBoxValue.fallbackText)} 
                </p>
              </div>
            </Col>
          </Row>
          <Row xs={12}>
            <Col xs={6} className="gridCol">
              <div
                className="pointsGridTwo"
                style={{ backgroundColor: styleGuide.color2 }}
              >
                <Headline3
                  style={{ color: styleGuide.color7 }}
                >{ points.bottomLeftBoxValue || '0'}</Headline3>
                <p
                  style={{
                    color: styleGuide.color6,
                    fontSize: styleGuide.explainerTextTinyFontSize,
                  }}
                >
                  {labels.bottomLeftBoxValue.configuredLabel ? labels.bottomLeftBoxValue.configuredLabel : translate(labels.bottomLeftBoxValue.translationKey, labels.bottomLeftBoxValue.fallbackText)}
                </p>
              </div>
            </Col>
            <Col xs={6} className="gridCol">
              <div
                className="pointsGridTwo"
                style={{ backgroundColor: styleGuide.color2 }}
              >
                <Headline3
                  style={{ color: styleGuide.color7 }}
                >{points.bottomRightBoxValue || '0'}</Headline3>
                <p
                  style={{
                    color: styleGuide.color6,
                    fontSize: styleGuide.explainerTextTinyFontSize,
                  }}
                >
                  {labels.bottomRightBoxValue.configuredLabel ? labels.bottomRightBoxValue.configuredLabel : translate(labels.bottomRightBoxValue.translationKey, labels.bottomRightBoxValue.fallbackText)}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
